import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";

import { BEAUTIFUL_WORKSPACE_ACTION } from "legacy-common/constants";
import { trackActivity } from "js/core/utilities/utilities";
import db from "js/db";
import { dialogTheme } from "legacy-js/react/materialThemeOverrides";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";
import {
    CompanySize,
    Intro,
    PresentationsType,
    UserRole,
    UserType
} from "./MarketingSurveyQuestions";

class MarketingSurveyImpl extends React.Component {
    state = {
        currentPane: 0,
        prevPane: 0,
        answers: {},
        progress: 0,
        prevProgress: 0,
        questions: [
            {
                question: "What kind of work do you do?",
                key: "userType",
                answers: ["marketing", "sales", "educator-or-student", "other"]
            },
            {
                question: "What is your role?",
                key: "userRole",
                answers: ["individual-contributor", "people-manager", "department-leader", "executive", "startup-founder", "consultant", "freelancer", "solo-preneur"]
            },
            {
                question: "How big is your company?",
                key: "companySize",
                answers: ["1", "2-19", "20-49", "50-99", "100-199", "200+"]
            },
            {
                question: "What kind of presentations will you create?",
                key: "presentationsType",
                answers: ["sales-pitches", "company-meetings", "hr-and-training", "customer-deliverables", "fundraising-decks", "conferences/events", "academic-reports", "lectures", "instructional-material", "personal-use"]
            }
        ],
        advanceCoefficient: 20,
        userDidJoinWorkspace: localStorage.getItem(BEAUTIFUL_WORKSPACE_ACTION) === "JOINED",
        userDidCreateWorkspace: localStorage.getItem(BEAUTIFUL_WORKSPACE_ACTION) === "CREATED"
    };

    normalizeSavedUserInfo = userInfo => {
        const { questions } = this.state;
        for (const key in userInfo) {
            const question = questions.find(q => q.key === key);
            if (!question) {
                delete userInfo[key];
            } else if (question.answers.indexOf(userInfo[key]) === -1) {
                delete userInfo[key];
            }
        }
    }

    async componentDidMount() {
        const { advanceCoefficient, userDidJoinWorkspace } = this.state;
        const { firebaseUser } = this.props;

        const userInfo = await db.getDbInstance("users").ref(`users/${firebaseUser.uid}/userInfo`).once("value").then(snapshot => snapshot.val());

        if (userDidJoinWorkspace) {
            this.setState({ advanceCoefficient: 25 });
        }

        if (userInfo) {
            this.normalizeSavedUserInfo(userInfo);

            const userInfoLength = Object.keys(userInfo).length;
            let advanceToPane;
            if (!userInfo["userType"]) {
                advanceToPane = 0;
            } else if (userInfo["userType"] === "educator-or-student" && userInfoLength === 2) {
                // If the first question is answered as "educator-or-student" and the length of answers is 2, go to the last pane
                advanceToPane = 4;
            } else if (userInfoLength > 0 && userInfoLength < 5) {
                advanceToPane = userInfoLength;
            } else {
                advanceToPane = 0;
            }

            this.setState({
                answers: userInfo,
                currentPane: advanceToPane,
                progress: advanceToPane * advanceCoefficient
            });
        }

        this.setState({ ready: true });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.answers !== this.state.answers) {
            await db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`).update({
                userInfo: this.state.answers
            });
        }
    }

    getPaneKey = pane => {
        switch (pane) {
            case 1:
                return "userType";
            case 2:
                return "userRole";
            case 3:
                return "companySize";
            case 4:
                return "presentationsType";
            default:
                return "";
        }
    };

    handleNext = () => {
        this.setState(prevState => {
            const nextPane = prevState.currentPane === 1 && prevState.answers["userType"] === "educator-or-student" ? 4 : prevState.currentPane + 1;
            const currentQuestion = this.state.questions[prevState.currentPane - 1];

            if (currentQuestion) {
                trackActivity("OnboardingSurvey", "QuestionAnswered", null, null, {
                    step_number: prevState.currentPane,
                    question_text: currentQuestion.question,
                    step_options_presented: currentQuestion.answers,
                    answer_submitted: prevState.answers[currentQuestion.key],
                    workspace_id: "all"
                }, { audit: true });

                trackActivity("SignupFlow", "NavForward", null, null, {
                    step_number: prevState.currentPane + 2,
                    step_name: `Onboarding Survey Step ${prevState.currentPane}`,
                    object: "button",
                    object_label: prevState.answers[currentQuestion.key],
                    action: "clicked",
                    workspace_id: "all"
                }, { audit: true });
            }

            if (nextPane === 5) {
                this.handleSubmit();
                return;
            }

            return {
                prevPane: prevState.currentPane,
                currentPane: nextPane,
                prevProgress: prevState.progress,
                progress: nextPane * prevState.advanceCoefficient
            };
        });
    };

    handleBack = () => {
        this.setState(prevState => {
            let nextPane;
            const currentPaneKey = this.getPaneKey(prevState.currentPane);
            const updatedAnswers = { ...prevState.answers };
            delete updatedAnswers[currentPaneKey];

            if (currentPaneKey === "presentationsType" && prevState.answers["userType"] === "educator-or-student") {
                delete updatedAnswers["companySize"];
                delete updatedAnswers["presentationsType"];
                nextPane = 1;
            } else {
                nextPane = prevState.currentPane - 1;
            }

            const currentQuestion = this.state.questions[prevState.currentPane - 1];
            if (currentQuestion) {
                trackActivity("OnboardingSurvey", "BackButtonClicked", null, null, {
                    step_number: prevState.currentPane,
                    question_text: currentQuestion.question,
                    step_options_presented: currentQuestion.answers,
                    answer_submitted: updatedAnswers[currentQuestion.key],
                    workspace_id: "all"
                }, { audit: true });

                trackActivity("SignupFlow", "NavBack", null, null, {
                    step_number: prevState.currentPane + 2,
                    step_name: `Onboarding Survey Step ${prevState.currentPane}`,
                    object: "button",
                    object_label: updatedAnswers[currentQuestion.key],
                    action: "clicked",
                    workspace_id: "all"
                }, { audit: true });
            }

            return {
                prevPane: prevState.currentPane,
                currentPane: nextPane,
                prevProgress: prevState.progress,
                progress: nextPane * prevState.advanceCoefficient,
                answers: updatedAnswers
            };
        });
    };

    handleChange = (key, value) => {
        this.setState(state => {
            let updatedQuestions = [...state.questions];

            if (key === "userType") {
                let newAnswers;
                switch (value) {
                    case "marketing":
                        newAnswers = ["company-meetings", "conferences/events", "customer-deliverables", "sales-pitches", "hr-and-training", "fundraising-decks"];
                        break;
                    case "sales":
                        newAnswers = ["sales-pitches", "company-meetings", "hr-and-training", "customer-deliverables", "fundraising-decks", "conferences/events"];
                        break;
                    case "educator-or-student":
                        newAnswers = ["conferences/events", "academic-reports", "lectures", "instructional-material", "personal-use"];
                        break;
                    case "other":
                        newAnswers = ["sales-pitches", "company-meetings", "hr-and-training", "customer-deliverables", "fundraising-decks", "conferences/events", "personal-use"];
                        break;
                    default:
                        break;
                }

                updatedQuestions = updatedQuestions.map(question => {
                    if (question.key === "presentationsType") {
                        return {
                            ...question,
                            answers: newAnswers
                        };
                    }
                    return question;
                });
            }

            return {
                answers: { ...state.answers, [key]: value },
                questions: updatedQuestions
            };
        });
    };

    handleSubmit = async () => {
        const { onFinished } = this.props;
        const { answers } = this.state;

        if (answers["userType"] === "educator-or-student") {
            delete answers.userRole;
            delete answers.companySize;
        }

        answers.complete = true;

        await db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`).update({
            userInfo: answers
        });

        trackActivity("OnboardingSurvey", "Complete", null, null, { survery: this.state.answers, workspace_id: "all" }, { audit: true });

        setTimeout(() => {
            onFinished();
        }, 750);
    }

    renderQuestionsPane = () => {
        const { ready, currentPane, prevPane, answers, prevProgress, progress } = this.state;

        if (!ready) {
            return null;
        }

        const paneProps = {
            prevProgress,
            currProgress: progress,
            paneIdx: currentPane,
            prevPaneIdx: prevPane
        };

        switch (currentPane) {
            case 0:
                return (<Intro onNext={this.handleNext}
                    {...paneProps}
                />);
            case 1:
                return (<UserType
                    {...paneProps}
                    onNext={this.handleNext}
                    handleChange={this.handleChange}
                    value={answers["userType"] || ""}
                    skipSurvey={this.handleSubmit}
                />);
            case 2:
                return (<UserRole
                    {...paneProps}
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    handleChange={this.handleChange}
                    value={answers["userRole"] || ""}
                />);
            case 3:
                return (<CompanySize
                    {...paneProps}
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    handleChange={this.handleChange}
                    value={answers["companySize"] || ""}
                />);
            case 4:
                return (<PresentationsType
                    {...paneProps}
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    handleChange={this.handleChange}
                    value={answers["presentationsType"] || ""}
                    firstQuestionValue={answers["userType"] || ""}
                />);
            default:
                return null;
        }
    }

    render() {
        return (<MuiThemeProvider theme={dialogTheme}>
            {this.renderQuestionsPane()}
        </MuiThemeProvider>);
    }
}

export const MarketingSurvey = withFirebaseUser(MarketingSurveyImpl);
