import React from "react";

import { SubscriptionStatus } from "legacy-common/constants";
import { getExperiments } from "js/core/services/experiments";
import db from "js/db";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";

import { ChoosePlan } from "./ChoosePlan";
import { MarketingSurvey } from "./MarketingSurvey";

class Onboarding extends React.Component {
    state = {};

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { firebaseUser, isCheckout } = this.props;

        if (firebaseUser.uid !== prevProps.firebaseUser.uid) {
            this.load();
        }

        if (isCheckout !== prevProps.isCheckout) {
            this.load();
        }
    }

    async load() {
        const { isCheckout, plan } = this.props;

        const userRef = db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`);
        const user = await userRef.once("value").then(snap => snap.val());

        let {
            onboarding_plan_confirm: { enabled: showPlan },
            onboarding_survey: { enabled: showSurvey }
        } = await getExperiments(["onboarding_plan_confirm", "onboarding_survey"]);

        ///// Legacy logic /////
        // Don't show pro plan chooser to team users, or editing collaborators
        if (window.baiSource === "team-invite" || window.baiSource === "collab:edit") {
            showPlan = false;
            await userRef.update({
                didConfirmPlan: true
            });
            user.didConfirmPlan = true;
        }

        // Don't show tour for team invites
        if (window.baiSource === "team-invite") {
            await userRef.update({
                hasTakenTour: true
            });
            user.hasTakenTour = true;
        }

        // Don't show survey to editing collaborators
        if (window.baiSource === "collab:edit") {
            showSurvey = false;
        }
        ////////////////////////

        // Don't show survey to enterprise customers on checkout
        if (isCheckout && plan === "enterprise") {
            showSurvey = false;
        }

        this.setState({
            user,
            showPlan,
            showSurvey
        });
    }

    handleFinished = () => {
        // Reload user and see what's changed
        this.load();
    }

    render() {
        const { children, isCheckout } = this.props;
        const { user, showSurvey, showPlan } = this.state;

        if (!user) {
            return null;
        }

        if (user.edu) {
            return children;
        }

        const unknownStatus = user.stripeSubscriptionId != undefined && user.subscriptionStatus == undefined;
        const hasPlan = user.subscriptionStatus === SubscriptionStatus.ACTIVE || user.subscriptionStatus === SubscriptionStatus.TRIALING || user.subscriptionStatus === SubscriptionStatus.PAST_DUE || unknownStatus;
        const showMarketingSurvey = !user.userInfo?.complete && showSurvey;
        const showChoosePlan = !user.didConfirmPlan && !hasPlan && !user.hasTakenTeamTrial && showPlan;

        if (showMarketingSurvey) {
            return <MarketingSurvey onFinished={this.handleFinished} />;
        }
        if (showChoosePlan && !isCheckout) {
            return <ChoosePlan onFinished={this.handleFinished} />;
        }

        return children;
    }
}

export default withFirebaseUser(Onboarding);
