import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { IconButton } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";

import { getStaticUrl } from "legacy-js/config";

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 23px;
    left: 128px;

    @media (max-width: 768px) {
        left: 10px;
    }
`;

const LogoImage = styled.img.attrs(({ offsetRight }) => ({
    style: {
        marginLeft: offsetRight ? 40 : 0
    }
}))`
    width: 132.855px;
    height: 22px;
    margin-bottom: 8px;
    position: relative;
    transition: margin-left 0.5s ease-in-out;
`;

const IconButtonStyled = styled(IconButton).attrs(({ hidden }) => ({
    style: {
        opacity: hidden ? 0 : 1
    }
}))`
    &&& {
        bottom: 5px;
        position: absolute;
        transition: opacity 0.5s ease-in-out;
    }
`;

function Header({ onBackButtonClick = () => { }, showBackButton = false, prevShowBackButton = false }) {
    const [backButtonVisible, setBackButtonVisible] = useState(prevShowBackButton);

    useEffect(() => {
        setTimeout(() => {
            setBackButtonVisible(showBackButton);
        }, 100);
    }, []);

    return (
        <HeaderContainer>
            <IconButtonStyled
                hidden={!backButtonVisible}
                size="small"
                onClick={() => onBackButtonClick()}
            >
                <ArrowBackIos style={{ fontSize: "16px", paddingLeft: "5px" }} />
            </IconButtonStyled>
            <LogoImage
                src={getStaticUrl("/images/onboarding/beautiful-header.svg")}
                alt="Logo"
                offsetRight={backButtonVisible}
            />
        </HeaderContainer>
    );
}

export default Header;
